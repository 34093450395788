import * as React from "react"
import Seo from "@components/seo"
import Layout from "@layout"
import Banner from "@components/banners/Ai";
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import PublicationSPArea from "@containers/publicationSP-area"

const PublicationSPPage = ({pageContext, location }) => (
  <Layout location={location}>
    <Seo title="All Publications" />
    <Header />
    {<Banner
			pageContext={pageContext}
			location={location}
			title="Publications on Privacy & Security"
		/> }
    <main className="site-wrapper-reveal">
      <PublicationSPArea />
    </main>
    <Footer />
  </Layout>
)

export default PublicationSPPage
